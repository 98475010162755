@import '../../config.scss';

.headerDirectory {
  width: 32rem;
  padding: 1.5rem 1rem 1rem 1rem;
  background-color: white;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 2.3rem;
  display: flex;
  flex-wrap: wrap;
  // animation
  animation-name: appear;
  animation-duration: 0.5s;

  @include respond(phone) {
    display: none;
  }

  .directoryItem {
    width: 10rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    img {
      width: 8rem;
      height: 8rem;
      object-fit: cover;
      border: solid 1px primaryColorLight;
      opacity: 0.9;
      filter: brightness(0.9);
      transition: all 0.3s;
    }

    &:hover img {
      opacity: 1;
      filter: brightness(1);
    }

    span {
      font-size: 1.4rem;
      transition: all 0.3s;
    }

    &:hover span {
      color: secondaryColor;
    }
  }
}
