@import '../../config.scss';

.saleMessage {
  background-color: secondaryColorLight;
  padding: 0.5rem 0;
  text-align: center;
  position: relative;

  @include respond(phone) {
    display: none;
  }

  .messageClear {
    position: absolute;
    top: 0.2rem;
    right: 0;
    margin: 0 1rem;
    cursor: pointer;

    svg {
      fill: primaryColor;
      width: 2rem;
      height: 2rem;
      transition: all 0.3s;

      &:hover {
        fill: secondaryColorDark;
      }
    }
  }
}
