@import '../../config.scss';

.errorMessageOverlay {
  grid-column: full-start / full-end;
  min-height: 65vh;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .errorMessageContainer {
    display: flex;
    flex-direction: column;
    align-items: center;

    pre {
      font-family: 'Dosis', Microsoft JhengHei, sans-serif;
      text-align: center;
      color: primaryColorDark;
    }
  }
}
