@import '../../config.scss';

.scrollToTopButton {
  position: fixed;
  right: 3rem;
  bottom: 3rem;
  cursor: pointer;
  width: 4.5rem;
  height: 4.5rem;
  border: solid 1px secondaryColor;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s;

  @include respond(phone) {
    right: 1.5rem;
    bottom: 1.5rem;
    width: 4rem;
    height: 4rem;
  }

  &:hover {
    border: solid 1px secondaryColorDark;
  }

  &:hover svg {
    color: secondaryColorDark;
  }

  svg {
    width: 3rem;
    height: 3rem;
    color: secondaryColor;
    transition: all 0.3s;

    @include respond(phone) {
      width: 2.5rem;
      height: 2.5rem;
    }
  }
}
