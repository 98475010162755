@import '../../config.scss';

@value sideNavWidth: 40rem;

.sideNav {
  position: fixed;
  top: 0;
  left: calc(-1 * sideNavWidth);
  transition: all 0.5s;
  z-index: 1002;
  height: 100vh;
  display: flex;

  @include respond(tab-port) {
    font-size: 1.8rem;
  }

  .content {
    width: sideNavWidth;
    background-color: secondaryBackgroundLight;

    li {
      list-style-type: none;
      padding: 1rem 0;
      cursor: pointer;
      transition: all 0.3s;

      &:hover {
        color: secondaryColorDark;
      }
    }

    .upper {
      margin: 0;
      padding: 2.5rem 5rem;
      background-color: secondaryBackgroundDark;
      font-weight: 600;

      li {
        display: flex;
        align-items: center;
        color: white;

        &:hover {
          color: secondaryColorDark;
        }

        svg {
          width: 3rem;
          height: 3rem;
          fill: white;
          transition: all 0.3s;
        }

        &:hover svg {
          fill: secondaryColorDark;
        }
      }
    }

    .middle,
    .lower {
      margin: 5rem;
      padding-left: 0;

      li {
        border-bottom: solid 1px rgb(202, 202, 202);
      }
    }

    .middle {
      .title {
        display: flex;
        justify-content: space-between;

        svg {
          width: 2rem;
          height: 2rem;
          fill: primaryColor;
        }

        &:hover a,
        &:hover svg {
          color: secondaryColorDark;
          fill: secondaryColorDark;
          transition: all 0.3s;
        }
      }

      .category {
        li {
          border-bottom: none;
        }
      }
    }
  }
}

.sideNavActive {
  left: 0;

  .background {
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.3);
  }
}
