@import '../../config.scss';

.cartItem {
  width: 100%;
  height: 8rem;
  margin-bottom: 0.5rem;
  font-size: 1.3rem;
  background-color: rgba(255, 255, 255, 0.4);
  display: grid;
  grid-template-columns: 0.8fr 1.5fr 0.7fr;
  grid-gap: 1rem;

  @include respond(tab-port) {
    font-size: 1.5rem;
  }

  @include respond(phone) {
    font-size: 1.8rem;
  }

  img {
    width: 8rem;
    height: 8rem;
    object-fit: cover;
  }

  .group {
    text-align: right;
    margin-right: 0.5rem;

    .quantity {
      font-size: 1.2rem;

      @include respond(tab-port) {
        font-size: 1.4rem;
      }

      @include respond(phone) {
        font-size: 1.7rem;
      }
    }
  }
}
