@import '../../config.scss';

.search {
  position: relative;

  .searchContainer {
    position: absolute;
    top: 0.5rem;
    left: 4rem;

    @include respond(phone) {
      position: fixed;
      top: 8.7rem;
      left: -1vw;
      background-color: rgba(0, 0, 0, 0.3);
      width: 102vw;
      height: 100vh;
    }

    .searchForm {
      .searchInput {
        width: 12rem;
        font-size: 1.5rem;
        font-family: 'Dosis', sans-serif;
        color: primaryColor;
        padding: 0.3rem;
        border: none;
        border-bottom: solid 0.5px primaryColorLight;

        @include respond(phone) {
          font-size: 2rem;
          width: 102vw;
          padding: 2rem 6rem;
          border-top: solid 0.5px primaryColorLight;
        }

        &:focus {
          outline: none;
        }
      }
    }
  }
}
