@import '../../config.scss';

.cartDropdown {
  position: absolute;
  right: 0;
  top: 4rem;
  width: 34rem;
  max-height: 65vh;
  padding: 2rem;
  background-color: secondaryBackgroundLight;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
  color: primaryColorDark;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  // animation
  animation-name: appear;
  animation-duration: 0.5s;

  @include respond(phone) {
    display: none;
  }

  .cartItems {
    min-height: 8rem;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    margin: 1rem 0;
    border-bottom: solid 1px primaryColor;
  }

  .total {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
  }

  button {
    float: right;
  }
}
