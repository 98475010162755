@import '../../config.scss';

.footer {
  grid-column: full-start / full-end;
  background-color: secondaryColorLight;
  padding: 3rem 0;

  .lists {
    max-width: 120rem;
    width: 80%;
    margin: 2rem auto;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;

    @include respond(phone) {
      width: 90%;
    }
  }

  .list {
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
      margin: 0;
      text-decoration: underline;
      font-weight: 600;
    }

    ul {
      padding: 0;
      margin: 0.5rem;

      li {
        list-style-type: none;
        line-height: 2.5rem;
        text-align: center;

        svg {
          width: 3rem;
          height: 3rem;
          cursor: pointer;
          transition: all 0.3s;

          &:hover {
            color: secondaryColor;
          }

          @include respond(phone) {
            width: 4rem;
            height: 4rem;
          }
        }

        @include respond(phone) {
          margin: 1rem;
        }
      }
    }

    .socialList {
      display: flex;
    }
  }

  .copyright {
    display: flex;
    justify-content: center;
  }

  .footnote {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 2rem;

    span {
      margin: 0.5rem 1.5rem;
      letter-spacing: 1px;
      color: secondaryColorDark;

      a {
        color: secondaryColorDark;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
