@import '../../config.scss';

.shoppingCartBox {
  position: relative;

  .shoppingCartNum {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background-color: secondaryColorDark;
    position: absolute;
    top: -0.5rem;
    right: -0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;

    @include respond(phone) {
      width: 2.5rem;
      height: 2.5rem;
    }
  }
}
