// COLOR VARIABLES
@value primaryColor: rgb(128, 128, 128);
@value primaryColorDark: rgb(90, 90, 90);
@value primaryColorLight: rgb(223, 223, 223);
@value secondaryColor: rgb(255, 174, 171);
@value secondaryColorDark: rgb(223, 83, 78);
@value secondaryColorLight: rgb(241, 229, 229);
@value secondaryBackgroundDark: rgb(223, 164, 164);
@value secondaryBackgroundLight: rgb(241, 235, 235);

// MIXINS FOR MEDIA QUERY
@mixin respond($breakpoint) {
  @if $breakpoint == phone {
    @media (max-width: 37.5rem) {
      @content;
    } //600px / 16px (browser default font size) = 37.5
  }
  @if $breakpoint == tab-port {
    @media (max-width: 56.25rem) {
      @content;
    } //900px / 16px = 56.25
  }
  @if $breakpoint == tab-land {
    @media (max-width: 75rem) {
      @content;
    } //1200px / 16px = 75
  }
  @if $breakpoint == big-desktop {
    @media (min-width: 125rem) {
      @content;
    } //2000px / 16px = 125
  }
}

// ANIMATION
@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
