@import '../../config.scss';

.popupMessage {
  position: absolute;
  right: 5vw;
  top: 13rem;
  width: 17rem;
  padding: 1rem;
  border: solid 2px secondaryColor;
  color: primaryColorDark;
  background-color: secondaryBackgroundLight;
  font-size: 1.4rem;
  display: flex;
  flex-direction: column;
  // animation
  animation-name: appear;
  animation-duration: 0.5s;

  @include respond(phone) {
    top: 10rem;
    width: 15rem;
  }

  .link {
    color: secondaryColorDark;
    text-decoration: underline;
    font-size: 1.2rem;
  }
}

// animation
@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
