@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@300;400&display=swap');
@import './config.scss';

html {
  box-sizing: border-box;

  // set root font size for rem ratio
  font-size: 62.5%; // 1rem = 10px, 10px/16px = 62.5%

  @include respond(tab-land) {
    font-size: 56.25%; // 1rem = 9px, 9px/16px = 56.25%
  }
  @include respond(tab-port) {
    font-size: 50%; // 1rem = 8px, 8px/16px = 50%
  }
  @include respond(phone) {
    font-size: 43.75%; // 1rem = 7px, 7px/16px = 43.75%
  }
  @include respond(big-desktop) {
    font-size: 75%; // 1rem = 12px, 12px/16px = 75%
  }
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  font-family: 'Dosis', Microsoft JhengHei, sans-serif;
  color: primaryColor;
  user-select: none;
  font-size: 1.5rem;

  a {
    text-decoration: none;
    color: primaryColor;
  }

  @include respond(tab-port) {
    font-size: 1.6rem;
  }

  @include respond(phone) {
    font-size: 1.8rem;
  }
}

.app {
  display: grid;
  grid-template-columns: [full-start] repeat(10, [col-start] minmax(min-content, 1fr) [col-end]) [full-end];
}
