@import '../../config.scss';

.header {
  grid-column: full-start / full-end;
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 1001;
  background-color: white;

  .mainHeader {
    padding: 1rem 0;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    border-bottom: solid 1px primaryColorLight;
    position: relative;

    .leftOptions {
      width: 16rem;
      padding-right: 8rem;
      display: flex;
      justify-content: space-between;

      @include respond(phone) {
        width: 10rem;
        padding-right: 0rem;
      }
    }

    .companyLogo {
      width: 18rem;
      height: auto;
      cursor: pointer;
    }

    .rightOptions {
      width: 16rem;
      display: flex;
      justify-content: space-between;

      @include respond(phone) {
        width: 10rem;
      }

      .contactIcon,
      .userIcon {
        @include respond(phone) {
          display: none;
        }
      }

      .cartGroup {
        position: relative;
      }
    }

    svg {
      fill: secondaryColor;
      cursor: pointer;
      width: 4rem;
      height: 4rem;
      padding: 0.5rem;
      transition: all 0.3s;

      &:hover {
        fill: secondaryColorDark;
      }

      @include respond(phone) {
        width: 5rem;
        height: 5rem;
      }
    }
  }

  .categoryList {
    padding: 0.5rem;
    border-bottom: solid 1px primaryColorLight;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    @include respond(tab-port) {
      font-size: 1.8rem;
    }

    @include respond(phone) {
      display: none;
    }

    span {
      cursor: pointer;
      padding: 0.5rem 3rem;
    }

    .directory {
      position: relative;
    }
  }
}
