@import '../../config.scss';

.languageSelector {
  position: relative;

  .languageList {
    position: absolute;
    right: 0;
    top: 4rem;
    display: flex;
    flex-direction: column;
    // animation
    animation-name: appear;
    animation-duration: 0.5s;

    @include respond(phone) {
      top: 5.5rem;
    }
  }
}
